<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <!-- <li class="breadcrumb-item active">Locations</li> -->
                        </ol>
                    </div>
                    <h4 class="page-title">Evaluation History</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex mb-4">
                            <label class="col-form-label me-2">Module</label>
                            <div style="width: 200px;">
                                <v-select v-model="module_id" label="name" :options="modules" :reduce="(name) => name.id"
                                    @option:selected="getFilterModule(module_id)" :clearable="false">
                                </v-select>
                            </div>
                            <label class="col-form-label me-2 ms-4">Trainer</label>
                            <div style="width: 200px;">
                                <v-select v-model="trainer" label="name" :options="trainers"
                                    @option:selected="getFilterTrainer(trainer)" :clearable="false">
                                </v-select>
                            </div>
                            <div @click="refreshData" class="icon-css">
                                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                            </div>
                        </div>

                        <place-holder v-if="loading"></place-holder>

                        <div class="table-responsive" v-show="!loading">
                            <table class="table table-striped dt-responsive w-100 mb-3" id="history-datatable"
                                v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th class="hideColumn">Module Id</th>
                                        <th>Module Name</th>
                                        <th>Batch</th>
                                        <th class="hideColumn">Trainer Employee Id</th>
                                        <th>Trainer</th>
                                        <th v-if="hasPermissions(['trainer-edit'])">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in evaluation_history" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td class="hideColumn">{{ item.module_id }}</td>
                                        <td>{{ item.module_name }}</td>
                                        <td>{{ item.batch }}</td>
                                        <td class="hideColumn">
                                            <span v-for="(trainer, index) in item.trainer" :key="index">{{
                                                trainer.employee_id
                                            }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-for="(trainer, index) in item.trainer" :key="index">{{
                                                trainer.name
                                            }}
                                                <span v-if="index + 1 < item.trainer.length">, </span>
                                            </span>
                                        </td>
                                        <td v-if="hasPermissions(['trainer-edit'])">
                                            <router-link :to="{
                                                name: 'evaluation-history-detail',
                                                params: { id: item.id },
                                            }">
                                                <button type="button" class="btn btn-sm btn-blue waves-effect waves-light">See
                                                    Detail</button>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
    components: {
        vSelect,
    },
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            evaluation_history: [],
            modules: [],
            module_id: "",
            trainer: "",
            trainers: [],
            employees: this.$store.getters["odoo/getAllEmployees"],
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        async getAllModules() {
            this.loading = true;
            this.$Progress.start();
            await axios.get(`${this.baseUrl}admin/v1/modules`).then((response) => {
                this.modules = response.data.data;
                this.loading = false;
                this.$Progress.finish();
            });
        },
        getTrainers() {
            axios
                .get(`${this.baseUrl}admin/v1/trainers`)
                .then((response) => {
                    this.trainers = response.data.data.map(function (val) {
                        if (val.status == 1) {
                            return {
                                employee_id: val.user.employee_id,
                                name: val.user.name,
                            };
                        }
                    });
                })
                .catch(() => {
                    this.toast.error("Not Found Trainer!");
                });
        },
        async getAllEvaluationHistory() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v1/evaluation-history`)
                .then((response) => {
                    this.evaluation_history = response.data.data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.toast.error("Something Went Wrong!");
                });
            $("#history-datatable").DataTable();
            this.clearFilter();
        },
        getFilterModule(id) {
            this.trainer = "";
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                console.log(settings, dataIndex);
                let moduleFilter = id;
                let moduleStage = data[1];
                if (moduleFilter == moduleStage) {
                    return true;
                }
                return false;
            });
            $("#history-datatable").DataTable().draw();
        },
        // getFilterTrainer(module_id, trainer) {
        //     console.log(module_id, trainer, "daa");
        //     this.clearFilter();
        //     $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        //         console.log(settings, dataIndex);
        //         let moduleFilter = module_id;
        //         let moduleStage = data[1];
        //         let trainerFilter = trainer.employee_id;
        //         let trainerStage = data[4];
        //         if (moduleFilter == moduleStage && trainerFilter == trainerStage) {
        //             return true;
        //         }
        //         return false;
        //     });
        //     $("#history-datatable").DataTable().draw();
        // },
        getFilterTrainer(trainer) {
            this.module_id = "";
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                console.log(settings, dataIndex);
                let trainerFilter = trainer.employee_id;
                let trainerStage = data[4];
                if (trainerFilter == trainerStage) {
                    return true;
                }
                return false;
            });
            $("#history-datatable").DataTable().draw();
        },
        refreshData() {
            this.module_id = "";
            this.trainer = "";
            this.filterData = [];
            this.getAllModules();
            this.getTrainers();
            this.getAllEvaluationHistory();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#history-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
        this.getAllModules();
        this.getTrainers();
        this.getAllEvaluationHistory();
    },
};
</script>
  
<style>
.hideColumn {
    display: none !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>